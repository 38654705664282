
.img-logo {
	height: 7rem;
	min-width: 7.25rem;
	padding:  1rem 2rem;
}
.header {
	background-color: var(--colour__white--100);
	display: flex;
	justify-content: space-between;
	@media screen and (min-width: 1080px) {
		.logo,
		.header-actions {
			flex: 1;
		}
	}
	.menu {
		background-color: var(--colour__white--100);
		display: flex;
		.btn-menu-toggle,
		.btn-search-toggle {
			display: flex;
			border-color: var(--colour__black--100);
			background-color: var(--colour__white--100);
			margin: auto;
			height: max-content;
			background-image: url(../media/images/menu.svg);
			background-size: 1rem;
			background-repeat: no-repeat;
			background-position: 50%;
			&.active {
				background-image: url(../media/images/x.svg);
			}
			.icon-menu {
				fill: transparent;
			}
		}
		.btn-menu-toggle,
		.btn-toggle-search {
			display: none;
		}
		@media screen and (max-width: 768px) {	
			flex: 1 1 100%;
			.menu-wrapper.menu-mobile {
				width: 100%;
				> ul {
					/* flex-direction: column; */
					display: block !important;
					max-height: 0vh;
					overflow: hidden;
					transition: max-height linear 0.5s;
					background-color: var(--colour__white--100);
					box-shadow: var(--settings__shadow--1);
					&.active {
						max-height: 80vh;
						transition: max-height ease-in 1s;
					}
					.menu-item a {
						padding: 1rem;
						justify-content: flex-start;
						&:hover {
							text-decoration: underline;
							background-color: var(--colour__cool-grey--20);
						}
					}
				}

			}
			.btn-menu-toggle,
			.btn-toggle-search {
				display: flex;
				align-self: center;
				margin: auto 0;
				height: max-content;
			}
		}

		
		.menu-wrapper {
			display: flex;
			ul li {
				a {
					line-height: normal;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					padding: 0;
				}
			}
		}
		@media screen and (min-width: 769px) {	
			.menu-wrapper {
				> ul {
					display: flex !important; /* Override Configio important */
					justify-content: center;
					align-items: center;
					height: 100%;
					@media screen and (min-width: 960px) {
						gap:  2rem;
					}
				}
			}
			.menu-item {
				line-height: 1.2;
				height: 100%;
				border-bottom: 4px solid transparent;
				padding: 0;
				&:hover {
					border-bottom-color: var(--colour__black--100);
				}
				.menu-item-text {
					text-align: center;
				}
			}
			ul li a:hover {
				background-color: transparent;
				.menu-item-text {
					text-decoration: underline;
				}
			}	
		}
		@media screen and (min-width: 769px) and (max-width: 959px) {	
			.menu-wrapper > ul {
				gap:  1.5rem;
			}
		}
		@media screen and (min-width: 960px) and (max-width: 1279px) {	
			.menu-wrapper > ul {
				gap: 2rem;
			}
		}
		@media screen and (min-width: 1280px) {	
			.menu-wrapper > ul {
				gap:  3rem;
			}
		}
			
	}
	.header-actions {
		margin-left: 1rem;
		.account-buttons {
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 1rem;
			.button {
				margin: 0;
			}
		}
	}
}

.pre-header {
	position: relative;
	width: 100%;
	border-bottom: 1px solid var(--colour__cool-grey--100);
	padding: 0;
	.pre-header__home {
		display: flex;
	}
	.pre-header__home-link {
		display: flex;
		padding: 0.5rem 1rem;
		gap: 0.5rem;
		align-items: center;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}