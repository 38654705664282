.c-site-footer__baseline {
	border-top: 1px solid var(--colour__black--30);
	padding: calc((16/13) * 2rem) calc((16/13) * 1rem);
	.c-site-footer-text {
		margin-bottom: calc((16/13) * 0.5rem);
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.c-site-footer-text {
    font-family: var(--font-demi);
    font-size: calc((16/13) * 0.75rem);
    color: #4d4d4d;
    line-height: 1.35;
	&.c-site-footer-text--bold {
		font-family: var(--font-bold);
		font-style: normal;
		font-weight: normal;
		text-transform: none;
	}
}