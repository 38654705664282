html, body {
	font-size: 16px;
}

h1, h2, h3, h4, h5 {
	font-family: var(--font-bold) !important;
	line-height: 1.2;
	color: var(--colour__black--100) !important;
}
h1 {
	font-size: 48px;
}
h2 {
	font-size: 32px;
}
h3 {
	font-size: 20px;
}
h4 {
	font-size: 18px;
}
h5 {
	font-size: 16px;
}
@media screen and (min-width: 641px) and (max-width: 959px) {
	h1 {
		font-size: 40px;
	}
	h2 {
		font-size: 28px;
	}
}
@media screen and (max-width: 640px) {
	h1 {
		font-size: 32px;
	}
	h2 {
		font-size: 24px;
	}
}
h6 {
	font-family: var(--font-demi) !important;
	font-size: 12px;
	line-height: 1.2;
	text-transform: uppercase;
	color: var(--colour__black--100);
}
