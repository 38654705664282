body {
	font-family: var(--font-regular);
}
a {
	font-family: var(--font-demi);
	&:hover {
		text-decoration: underline;
	}
}
.body {
	background-color: var(--colour__cool-grey--20);
}
@media print, screen and (min-width: 39.5625em) {
    .grid-container {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}
.grid-container {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
}
.grid-x {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
}