@font-face{
	font-family:"AvenirNextLTW02-Regular";
	font-display: swap;
	src:local("AvenirNext-Regular"),
	url("../media/fonts/Avenir-Next/bb590848-4012-46d6-a8d5-48c9893a176b.woff2") format("woff2")
}

@font-face{
	font-family:"AvenirNextLTW02-Demi";
	font-display: swap;
	src:local("AvenirNext-DemiBold"),
	url("../media/fonts/Avenir-Next/b86524f7-009e-4c08-a266-c43f1fb68e5e.woff2") format("woff2")
}

@font-face{
	font-family:"AvenirNextLTW02-Bold";
	font-display: swap;
	src:local("AvenirNext-Bold"),
	url("../media/fonts/Avenir-Next/b729a5ee-0221-40db-9a76-7b9e9b747f65.woff2") format("woff2")
}
