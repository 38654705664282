.icon-inline {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-size: contain;
	background-repeat: no-repeat;
}

.icon--globe {
	background-image: url(../media/images/globe.svg);
}